import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';


import { useHistory } from "react-router-dom";


//currentComponent
import RoomServiceIcon from '@material-ui/icons/RoomService';
import userDataStore from '../../UserDataStore';
import './Dashboard.css'
import { Height } from '@material-ui/icons';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';


const useStyles = makeStyles(theme => ({
  root: {
     //padding: theme.spacing(4)
     maxWidth: 345,
     borderRadius: 10,
     background: 'rgba(112, 87, 87, 0.25);',
     position: 'center',
     position: 'relative',
     padding: 0,
  },

  body: {
    //  background: '#000428',
    //  background: '-webkit-linear-gradient(to right, #004e92, #000428)', 
    //  background: 'linear-gradient(to right, #004e92, #000428)' ,
    backgroundImage: 'url(/images/alberca.jpg)',
    // backgroundImage: 'no-repeat',
    // backgroundImage: 'fixed',
    // backgroundImage: 'center',
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
    fontFamily: 'sans-serif',
    Height: '3000px',
    width: 'auto%',
    margin: 'auto 10px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,


    
    
  },
   

styleCard: {
      width: '18rem'
    },

    cardDimension: {
        height: '200px',
        width: '330px'
    },

    imgCardDimension: {
      height: '180px',
        width: '318px'
    },

    imgCarrousel: {
      height: '500px',
      width: '400px'
    },

    space: {
      height: '500px',
      width: '500px'
    },

    spaceHeader: {
      height:'200px',
      width:'200px'
    }, 
    div1Pos:{
        position: 'relative',
        padding: 0,
      },

    div2Pos: {  
        position: 'absolute',
        left: '200px',
        top: 0,
      },

    fontStyle: {
      font: 'Arial',
      fontFamily: 'lauica',
      color: 'white',
      // alignContent: 'right',
      textAlign: 'right',
      position: 'absolute',
      top: '500px',
      left: 0,
      right: '70px',
      bottom: '30px',
      margin: 0,
      padding: 0,},

      fontColorWhiteTitle: {
        color: 'white',
        fontSize: "20px",
      },
      fontColorWhite: {
        color: 'white',
        fontSize: "large",
      },

      container:{
        color: 'white',
        textAlign: 'right',
        position: 'relative',
        top: '550px',
        right: '300px',
        bottom: '90px',
        left: "800px",
        margin: 0,
        padding: 0,
      },
}));

const Dashboard = () => {
  const classes = useStyles();
  userDataStore.setTitle('');
  userDataStore.setTitle('Dashboard');
  let history = useHistory();
  var auth = firebase.auth();

   const ShowMeUser = () => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log("positivo " + user.email);
        sentEmail(user.email);
      
      } else {
        // No user is signed in.
      }
    });
  }
  const sentEmail = (user) => {
    console.log("el user es: " + user);
    auth.sendPasswordResetEmail(user);
  }
  

  return (

    <div className={classes.body}>
    <div className={styles.h}></div>
      <div className={classes.space}>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>



<div class="jumbotron jumbotron-fluid"  className={classes.fontStyle}>

  <div class="container">
    <h1 class="display-4">Welcome to <br></br> Costa Bonita</h1>
    <p class="lead">Every good story, starts in the right place...</p>
    <button href="#" type="button" class="btn btn-primary"   
    onClick={()=>{
                history.push("/concierge")
              }} >Start
    </button>
  </div>
</div>
</div>


{/* <div className={classes.body}>
    <div className= "container" className={classes.container}>
      <div className="row"> 
        <div className = "one-half column">
        <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image='url(/images/alberca.jpg)'
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2"
          className={classes.fontColorWhiteTitle}>
           Welcome New Owners
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p"
          className={classes.fontColorWhite}>
            Here is the Welcome Package 
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="Green" class="btn btn-success" 
        onClick={()=>{
          window.open("https://ownersapp.costabonita.mx/assets/pdf/8ztxefudqpfgt6vyf3wbww.pdf")
        }}>
          Learn More
        </Button>
      </CardActions>
    </Card>
         </div>

         <br></br>
        <div className = "one-half column">
        <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image="https://ownersapp.costabonita.mx/assets/images/21a1nruxth813m9pz5mn01e.jpeg"
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2"
          className={classes.fontColorWhiteTitle}>
            Welcome to Costa Bonita
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p"
          className={classes.fontColorWhite}>
            Make an Apoinment
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="#227bee" class="btn btn-primary" 
        onClick={()=>{
          history.push("/concierge")
        }}>
          Start
        </Button>
      </CardActions>
    </Card>
         
         </div>
       </div>
    </div>
    </div> */}


</div>
  ); 
};
export default Dashboard;

