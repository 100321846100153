import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, Button } from '@material-ui/core';
const useStyles = makeStyles(() => ({
    root: { textAlign: "right"}
  }));
  
  const AccountDetails = props => {
    const { className,file, close, ...rest } = props;
    const classes = useStyles();

    return (
    <Card
    {...rest}
    className={clsx(classes.root, className)}
    >
        <iframe src={`https://drive.google.com/file/d/${file}/preview`} style={{height:'90vh',width:'100%'}}></iframe>
        <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={()=>{
            close();
        }}>
            CLOSE
        </Button>
    </Card>
    );
  };

  AccountDetails.propTypes = {
    className: PropTypes.string,
    file: PropTypes.string,
    close: PropTypes.func
  };
  
  export default AccountDetails;