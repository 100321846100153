import React, { useState } from 'react';
import rrd,{ Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Typography, Button } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ReplyIcon from "@material-ui/icons/Reply"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TitleObserver from '../../../../TitleObserver';
// import  '../../../../views/SignIn/SignIn.css'; 
// C:\Users\hich_\Desktop\costabonita-costabonita-182bd9b33b8d\owners\ClientApp\src\views\SignIn\SignIn.css
// C:\Users\hich_\Desktop\costabonita-costabonita-182bd9b33b8d\owners\ClientApp\src\layouts\Main\components\Topbar\Topbar.js

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
      boxShadow: 'none',
      backgroundColor: 'rgb(66, 63, 63)',
    }
  },
  root: {
    // boxShadow: 'none',
    // backgroundColor: 'rgba(112, 87, 87, 0.25)',
    
    // boxShadow:'1px 1px 50px #000',
    // backgroundColor: 'rgba(212, 152, 152, 0)',
    // backgroundcolor:'blue',
    // position: 'absolute',
    display: '20%',
    margin: 'auto',
    // backgroundImage: `url(/images/blacksand.jpg)`

     backgroundColor: 'rgb(66, 63, 63)'

    // borderRadius: 10,
    // background: 'rgba(112, 87, 87, 0.25);',
  },
  flexGrow: {
    flex: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  customHeader: {
    // backgroundColor: 'gray',
    //  backgroundColor: 'rgba(212, 152, 152, 0)',
  //  colorAdjust:'gray',
    // opacity: '100%',
     background: 'rgba(112, 87, 87, 0.25);',
    // background: 'rgba(37, 33, 33, 0.041)',
    // boxShadow:'1px 1px 50px #000',
    // display: 'none'
    // backgroundImage: `url(/images/headerbg.jpg)`
  },
  customImg: {
    opacity: '100%'
  },
  test:{
    backgroundColor:'red'
  },
  backColorButon: {
    backgroundColor: 'rgba(48, 43, 43, 0.199)'
  },
  iconColor: {
    backgroundColor: 'white'
  },

}));

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;
  const matches = useMediaQuery('(min-width:600px)');
  const classes = useStyles();
  const [notifications] = useState([]);

  function mensaje (){
      console.log("lo intentamos");
  } 

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar className={classes.customHeader}>
        <div className={classes.flexGrow}>
         
        <RouterLink to="/" >
          {matches?
          (<img
            alt="Logo"
            src= "/images/logos/logo.png"
          />):
          (<img
            alt="Logo"
            src= "/images/logos/minilogo.png"
          />)
        }
        </RouterLink>
        </div>
        <div className={classes.flexGrow}>
        <TitleObserver title="Default" />
        </div>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          
           <IconButton >
            <svg className={classes.iconColor} width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrow-left-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  color="inherit"
            onClick={()=>{
              window.history.back();
            }}>
            <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path fill-rule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"/>
            </svg>
          </IconButton>
         
        </Hidden>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
