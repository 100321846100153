import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card } from '@material-ui/core';
import userDataStore from '../../UserDataStore';
import ProviderItem from './components/ProviderItem';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import ProviderTable from './components/ProviderTable/ProviderTable';
import ProviderToolbar from './components/ProviderToldbar/ProviderToldbar';

const PROVIDERSBYSERVICES = gql`
query providers_by_services {
    services(order_by: {name: asc}) {
      id
      name
      providers_services {
        id
        provider {
          email
          id
          name
          phone
          rate
        }
      }
    }
  }
`;
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    }
  }));


const Provider = () => {
    const { loading:loadingProviders, error:errorProviders, data:dataProviders } = useQuery(PROVIDERSBYSERVICES);
    userDataStore.setTitle('');
    userDataStore.setTitle('provider');
    const [query,setQuery] = useState('');

    
    const HanddleQueryChange = (event) => {
      setQuery(event.target.value);
    };

    // searchTerm = "";
    
    // editSearch = (e) => {
    //   this.setState({searchTerm: e.target.value})
    // }

    // dynamicSearch = () => {
    //   return this.userDataStore.name.filter(name => name.toLowerCase().includes(this.userDataStore.searchTerm.toLowerCase()))
    // }



    const classes = useStyles();
    if(loadingProviders)
    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12} >Loading......</Grid>
            </Grid>
        </div>
    );
    return (
        //  <div className={classes.root}>
        //    <input type="text" value = {this.userDataStore.searchTerm} onChange = {this.setState.searchTerm.toLowerCase()} placeholder= "Search for a Service"/>
        //      <Grid container spacing={4} >
        //          {dataProviders.services.map((currentService) => {
        //              return currentService.providers_services.map((currentProvider) => {
        //                  return (
        //                      <Grid item xs={12} >
        //                        <Card>
        //                            <ProviderItem
        //                          service = {currentService.name}
        //                          phone={currentProvider.provider.phone}
        //                          email={currentProvider.provider.email}
        //                          fullName={currentProvider.provider.name}
        //                          />    
        //                          <button type="button" class="btn btn-warning">Rate</button>
        //                          <button type="button" class="btn btn-warning">Ratings</button>
        //                        </Card>
        //                      </Grid> 
        //                  )
        //              })
        //          })}
              
        //      </Grid>
         
        //  </div>


         <div className= {classes.root}>
           
             {/* <ProviderToolbar  query={query} setQuery={HanddleQueryChange.bind(this)}/>     */}
         <div className={classes.content}>
               <ProviderTable  data={dataProviders} />  
         </div>
         </div>
    );
};

export default Provider;