import { observable, decorate } from "mobx"
import { observer } from "mobx-react";

class ObservableUserDataStore {
    currentTitle = 'Default';
    userData = (localStorage.getItem("userData"))?JSON.parse(localStorage.getItem("userData")):{
        uid: '',
        fullName: '',
        email: '',
        jwt: '',
      };

    updateUser(udata){
        Object.keys(udata).map((current)=>{
            this.userData[current] = udata[current];
        });
        localStorage.setItem("userData",JSON.stringify(this.userData));
    }

    clearUser(){
        Object.keys(this.userData).map((current)=>{
            this.userData[current] = '';
        });
        localStorage.clear();
    }

    setTitle(_title){
        this.currentTitle = _title;
    }

}
observer(ObservableUserDataStore)
decorate(ObservableUserDataStore,{
    userData: observable,
    currentTitle: observable
})
const userDataStore = new ObservableUserDataStore();
export default userDataStore;