import React, { useState, useEffect, Fragment } from 'react';
import { useAuthFirebase } from "../Auth/react-auth-firebase";
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import userDataStore from '../../UserDataStore';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Link,
  Typography,
  Collapse,
} from '@material-ui/core';
import { Alert } from "@material-ui/lab";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import { useApolloClient } from '@apollo/react-hooks';
import { red } from '@material-ui/core/colors';
//import { SignInView } from './SignView';
 import logoCostaBonita from './logo.png';
//import './Wallpaper.css';
 import  './MainComponentStyle.css';
import { CropLandscapeSharp, SettingsOutlined } from '@material-ui/icons';
// C:\Users\hich_\Desktop\costabonita-costabonita-182bd9b33b8d\owners\ClientApp\src\views\SignIn\MainComponentStyle.css
import firebase, { auth } from "firebase/app";
import { motion } from "framer-motion";


const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};


const useStyles = makeStyles(theme => ({

  body: {
    textAlign: 'center',
    backgroundImage: `url('costabonitalogin1.jpg')`,
    //backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
    fontFamily: 'sans-serif',
    Height: '3000px',
    width: 'auto%',
    margin: 'auto 10px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },

  form: {
    display: 'inline-block'
  },

  img: {
    display: 'block',
    margin: 'auto',
    height: '25px',
    width: '25px',
    top: 95,
    right: 95,
    bottom: 95,
    height: 'auto',
    cursor:"pointer"
  },

  signInButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '30px',
    margin: 'auto',
    width: '150px',
    height: '150px',
    borderradius: '50%',
    background: 'rgba(3,3,3,.8)',
    overflow: 'hidden',
    opacity: 0.4,
    boxshadow: '10px 10px 30px #000'
    },
    
  popo: {
    backgroundcolor:'blue',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '30px',
    margin: 'auto',
    width: '300px',
    height: '300px',
    borderRadius: 10,
    background: 'rgba(112, 87, 87, 0.25);',
    boxShadow:'1px 1px 50px #000'
    
  },

  closebtn: { 
    position: 'absolute',
    cursor: 'pointer',
    fontfamily:'Open Sans Condensed, sans-serif',
    lineheight: '18px',
    top: '3px',
    right: '3px',
    width: '25px',
    height: '25px',
    textAlign: 'center',
    borderradius:'5px',
    opacity: .2,
    webkittransition:'all 2s ease-in-out',
    moztransition: 'all 2s ease-in-out',
    otransition:'all 2s ease-in-out',
    backgroundColor: 'white',
    transition: 'all 0.2s ease-in-out'
  },
  
  // closebtn:'hover'{
  //   opacity: .5
  // },

  h1: {
     backgroundImage: 'url(logo.png)',
    // C:\Users\hich_\Desktop\costabonita-costabonita-182bd9b33b8d\owners\ClientApp\public\images\logos\logo.png
    fontfamily:'Open Sans Condensed, sans-serif',
    position: 'relative',
    marginTop:'0px',
    textAlign: 'center',
    fontSize: '40px',
    color: '#ddd',
    textShadow:'3px 3px 10px #000'
  },

  // a,
  input: {
  fontfamily: 'Open Sans Condensed, sans-serif',
  textdecoration: 'none',
  position: 'relative',
  width: '80%',
  display: 'block',
  margin: '9px auto',
  fontSize: '17px',
  color: 'rgb(7, 7, 7)',
  padding: '8px',
  borderRadius: '6px',
  border: 'none',
  background: 'rgba(3,3,3,.1)',
  webkittransition: 'all 2s ease-in-out',
  moztransition:'all 2s ease-in-out',
  otransition: 'all 2s ease-in-out',
  transition: 'all 0.2s ease-in-out',
  backgroundColor: 'white',
  focus: {
    outline: 'none',
    boxshadow: '3px 3px 10px #333',
    background: ' rgba(3,3,3,.18)'}
  },


  a: {
    fontfamily: 'Open Sans Condensed, sans-serif',
    borderRadius: '6px',
    marginTop: '15px',
    padding: '15px',
    position: "center",
    margin: 'auto',
    display: 'flex',
    textAlign: 'center',
    padding: '4px 8px',
    width: '80%',
    background:'rgba(107,255,3,0.3)',
    
    hover:{
      // backgroundColor: 'red'
      opacity: +10,
    }
      
  },

  
  textField: {
  fontfamily: 'Open Sans Condensed, sans-serif',
  textdecoration: 'none',
  // position: relative;
  // width: 80%;
  // display: block,
  // margin: 9px auto,
  // font-size: 17px,
  // color: '#fff',
  // padding: '8px',
  // border-radius: '6px';
  // border: none;
  // background: rgba(3,3,3,.1);
  // -webkit-transition: all 2s ease-in-out;
  // -moz-transition: all 2s ease-in-out;
  // -o-transition: all 2s ease-in-out;
  // transition: all 0.2s ease-in-out;
},

container: {
  position: 'aboslute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  //margin: auto,
  width: '260px',
  height: '260px',
  // border-radius: '5px',
  background: "rgba(3,3,3,0.25)",
  // box-shadow: '1px 1px 50px #000',
  display: 'none',
  color: '#ffffff' 
},

  backPhoto: {
    //backgroundImage:'url(costabonitalogin1.png)'
    //backgroundImage: 'url(/images/auth.jpg)',
    //backgroundimage: url('costaBoitalogin1.jpg')
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    backgroundPosition: 'center'
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/wallpaper/costabonitalogin1.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  fogottenContainer: {
    position: "relative",
    // margin: "-5px 20px",
    // padding: "8px",
    color: "white",
    cursor: "pointer",
    
    fontSize: "12px",
    fontFamily:" 'Hind', sans-serif",
    // color: 'rgba(255,255,255,.2)',
    right: '0px',
    top: "8px",
    hover: "color: rgba(255,255,255,.6)"
  },

  emailInput: {
    marginTop: "25px",
    top: 0,
    left: 0,
    rigth: 0,
    bottom: 0,
    position: "center",

  },

  forgottenBox: {
    backgroundcolor:'blue',
    textAlign: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '30px',
    margin: 'auto',
    width: '300px',
    height: '300px',
    borderRadius: 10,
    backgroundColor: "rgb(39, 37, 37);",
    boxShadow:'1px 1px 50px #000'
  },
  
  sentEmailButton: {
    marginTop: "30px",
    padding: "25px"
  },

  hide: {
  display: "none",
  hover: "opacity: .5"
  }
 

 
}));

const SignIn = props => {
  
  const { history } = props;
  const { loading, handdleSignIn } = useAuthFirebase();
  const [signInError, setSingInError] = useState({error:false,message:''});
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const styleHide = { Collapse: { display: "none" } }
  var auth = firebase.auth();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
    const [email, changePassword] = useState('');

     const sentEmail = (user) => {
      console.log("desde set email, el user es: " + user);
      auth.sendPasswordResetEmail(user);
      alert("check your email for registration");
    }

  useEffect(() => {
    const errors = validate(formState.values, schema);
  

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
    // return firebase.auth().onAuthStateChanged(async user => {
    //   if(user) {
    //     const token = await user.getIdToken();
    //     const idTokenResult = await user.getIdTokenResult();
    //     const hasuraClaim = idTokenResult.claims["https://hasura.io/jwt/claims"];
    //     if(hasuraClaim) {
    //       let userData = {
    //         uid: user.uid,
    //         fullName: '',
    //         email: user.email,
    //         jwt: idTokenResult.token,
    //         roles: hasuraClaim,
    //     };
    //       handleAuthState({status:'in',user,token})
    //       userDataStore.updateUser(userData)
    //       history.push('/dashboard')
    //     }
    //   } else {
    //     handleAuthState({ status: "out"});
    //   }
    // })
  }, [formState.values]);

  const handleBack = () => {
    history.goBack();
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = event => {
    event.preventDefault();
    const {values} = formState;
    handdleSignIn(values);
    
    //GETUSERBYEMAILCALLBACK()
    //loginCallback(values);
    // firebase.auth().signInWithEmailAndPassword(values.email, values.password).then((data)=>{
    //   //handleAuthState({status:'in',error:null})
    //   //console.log("Logged")
    //   //history.push('/');
    // }).catch(function(error) {
    //   if(error.code === 'auth/user-not-found'){
    //     console.log("no access for you")
    //   }
    //   else
    //   {
    //     //handleAuthState({status:'out',error:error});
    //   }
    // });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;


     document.body.style.background = "url('costabonitalogin1.jpg')";

   
  
  
    return (






<div className={classes.quote} >

    <motion.div className={classes.popo}
    whileHover={{scale: 1.1,
                  opacity: +5,
                  backgroundColor: "rgba(44, 43, 43, 0.911)",
                  color: "black"
                  
                }}
               
                
    >
     

      
          <form
                onSubmit={handleSignIn} >
               
                <Typography
                  // className={classes.h1}
                  variant="h2"  >
                  <img src={logoCostaBonita}></img>
                </Typography>


                <input
                  className={classes.input}
                  
                  error={hasError('email')}
                  fullWidth
                  helperText={
                    hasError('email') ? formState.errors.email[0] : null
                  }
                
                  label="Email address"
                  name="email"
                  onChange={handleChange}
                  type="text"
                  value={formState.values.email || ''}
                  variant="outlined"
                />
                <input 
                  className={classes.input}
                  error={hasError('password')}
                  fullWidth
                  helperText={
                    hasError('password') ? formState.errors.password[0] : null
                  }
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  type="password"
                  value={formState.values.password || ''}
                  variant="outlined"
                />
                <div>

                <motion.button className={classes.a}
                  whileHover={{scale: 1.1,
                    opacity: +5,
                    color: "black",
                    textAlign:"left" }}
                  // color="primary"
                  style={{textAlign:"center"}}
                  disabled={((!formState.isValid) || loading)}
                  position="center"
                  //fullWidth
                  // size="large"
                  type="submit"
                  // variant="contained"
                  >
                  Sign in now
                </motion.button>

                    <signInView> </signInView>


                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
                <Collapse in={signInError.error}
                className={classes.body}>
                  <Alert
                   severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                          setSingInError({error:false,message:''});
                        }}
                      >
                        <CloseIcon fontSize="inherit" />
                      </IconButton>
                    }
                  >
                    {signInError.message}
                  </Alert>
                </Collapse>
                <form >
                <Typography className={classes.fogottenContainer} 
                onClick={(event)=>{
                  setShowForm(!showForm);
                  setFormState(formState => ({
                    ...formState,
                    values: {}
                  }));
                }} >
                  Register Account
                </Typography>



                <Collapse in={showForm} className={classes.forgottenBox}>
                <div id="forgotten-container">
                <h1 >Forgotten</h1>
                <span class="close-btn">
                  
                <img className={classes.closebtn}
                src="https://cdn4.iconfinder.com/data/icons/miu/22/circle_close_delete_-128.png" 
                onClick={(event)=>{
                  setShowForm(!showForm);
                  setFormState(formState => ({
                    ...formState,
                    values: {}
                  }));
                }}>
                </img>
                </span>
                <input onChange={event => changePassword(event.target.value)} 
                className={classes.emailInput}
                type="email" 
                name="email" 
                placeholder="E-mail">
                </input>
               
                <Button 
                whileHover={{scale:1.1}}
                href="#" class="btn btn-primary" 
                onClick={ (event)=>{
                  setShowForm(!showForm);
                  sentEmail(email);
                  setFormState(formState => ({
                    ...formState,
                    values: {}
                  }));
                }}
                // ()=>{sentEmail(email)}} 
                >
                  Get new password 
                  </Button>
                </div>

                </Collapse> 
                </form>
  
                 {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don't have an account?{' '}
                  <Link
                    component={RouterLink}
                    to="/sign-up"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography>  */}
              </form>
         
      </motion.div>
         
  </div>

    
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
