import { gql } from 'apollo-boost';
import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { useQuery } from '@apollo/react-hooks';
import userDataStore from '../../UserDataStore';
import { Grid, Card } from '@material-ui/core';


const GETMOTIONS = gql`query MyQuery {
    motions {
      id_user
      name
      description
      date
    }
  }
  `;
  const INSERTMOTIONS = gql`mutation MyMutation($object: motions_insert_input!) {
    insert_motions_one(object: $object) {
      description
      id_user
      name
      id
      date
    }
  }`;

//   const GETUSERNAME =gql``;


  const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    constructionimg: {
        textAlign: 'center',
        padding: theme.spacing(4),
        // backgroundImage: 'url(/images/work-in-progress.png)',
        // backgroundImage: `url('costabonitalogin1.jpg')`,
        // backgroundImage: 'url(/images/auth.jpg)',
        backgroundImage: 'url(/images/under-construction.jpeg)',
        // height: "100px",
        // width: "150px"
    }
  }));

const Motions = () => {

const { loading:loadingMotions, error:errorMotions, data:dataMotions } = useQuery(GETMOTIONS);
userDataStore.setTitle('Motions');


const classes = useStyles();
return (
  <div className={classes.constructionimg}>
    
    <img src="work-in-progress.png"></img>
  </div>
);

    // if(loadingMotions)
    // return (
    //     <div className={classes.root}>
    //         <Grid container spacing={4} >
    //             <Grid item xs={12} >Loading......</Grid>
    //         </Grid>
    //     </div>
    // );
    // return(
    // <div className={classes.root}>
            {/* <Grid container spacing={4} >
                  {dataMotions.services.map((currentService) => {
                      return currentService.map((currentProvider) => {
                          return (
                              <Grid item xs={12} >
                                <Card>
                                    <div
                                  service = {currentService.name}
                                  description = {currentService.description}
                                  />    
                                  <button type="button" class="btn btn-warning">Rate</button>
                                  <button type="button" class="btn btn-warning">Ratings</button>
                                </Card>
                              </Grid> 
                          )
                      })
                  })} */}
              
              {/* </Grid> */}

    //           <img src=""> </img>
         
    // </div>

// );
} 
export default Motions;