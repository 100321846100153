import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { AccountDetails } from './components';
import { Notifications } from './components';
import userDataStore from "../../UserDataStore";
import { from, gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from "react-router-dom";

const GETMYUSERDATA = gql`
query getmyuserdata($uid: String!) {
  users(where: {uid: {_eq: $uid}}) {
    email
    first_name
    last_name
    phone_number
    uid
    id
  }
}
`;
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  userDataStore.setTitle('account');
  const classes = useStyles();
  const {loading,error,data} = useQuery(GETMYUSERDATA,{variables:{uid:userDataStore.userData.uid}});
  if(loading)
  return (<div className={classes.root}></div>)
  return (
    <div className={classes.root}>
      {(error !== undefined)?
      []:
      (<Grid
        container
        spacing={4}
      >
        <Grid
          item
          lg={8}
          md={6}
          xl={8}
          xs={12}
        >
          <AccountDetails user={(data.users.length > 0)?data.users[0]:null} />
          &nbsp;
          &nbsp;
          <Notifications />
        </Grid>
      </Grid>)
      }
      
    </div>
  );
};

export default Account;
