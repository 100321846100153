import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton
} from '@material-ui/core';
import { useSubscription,useQuery,useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";


const DELETEVISITGQL = gql`
mutation MyMutation($id: Int !) {
  delete_visits_by_pk(id: $id) {
    id
    created_by
    comments
    car_color
    name
  }
}
`;

const UPDATEENTRYVISITGQL = gql`
mutation UpdateCheckIn($pk_columns: visits_pk_columns_input!, $checking_in: timestamptz = "") {
  update_visits_by_pk(pk_columns: $pk_columns, _set: {checking_in: $checking_in}) {
    checking_in
  }
}
`;

const UPDATEDEPARTUREVISITGQL = gql `mutation UpdateCheckIn($pk_columns: visits_pk_columns_input!, $checking_out: timestamptz = "") {
  update_visits_by_pk(pk_columns: $pk_columns, _set: {checking_out: $checking_out}) {
    checking_out
  }
}
`;

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
  }
}`;


const moment = require('moment');

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, visits, search, ...rest } = props;

  const classes = useStyles();
  const [deleteVisitGql,{loading:loadingData,data,error:deleteError}] = useMutation(DELETEVISITGQL);
  const [updateEntryVisitGql,{loading:loadingDataEntry,dataEntry,error:updateEntryError}] = useMutation(UPDATEENTRYVISITGQL);
  const [updateDepartureVisitGql,{loading:loadingDataDeparture,dataDeparture,error:updateDepartureError}] = useMutation(UPDATEDEPARTUREVISITGQL);

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;

  const {
    data: userDatas,
    loading: userLoadingError,
    error: userDataError
  } = useSubscription(GETUSERROLEBYEMAIL, {
    variables: { email: emailCustom }
  });


  const deleteVisit = (id) =>{
    let answer = window.confirm(`are you sure to delete this visit ?`+ id);
    if(answer === true)
    deleteVisitGql({variables:{id}})
  };
 
  const updateEntryVisit = (id) =>{
    let answer = window.confirm(`are you sure to registrer the entry time of this visit ?`);
    const currentTimestamp = new Date().toISOString();
    if(answer === true)
    updateEntryVisitGql({
      variables: {
        pk_columns: { id: id },
        checking_in: currentTimestamp,
      },
    });
  };

  const updateDepartureVisit = (id) =>{
    let answer = window.confirm(`are you sure to registrer the departure time of this visit ?`);
    const currentTimestamp = new Date().toISOString();
    if(answer === true)
    updateDepartureVisitGql({
      variables: {
        pk_columns: { id: id },
        checking_out: currentTimestamp,
      },
    });
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };
  const filter = (current)=>{
    return (search === '' || current.name.toLowerCase().search(search.toLowerCase()) >= 0);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Visit</TableCell>
                  <TableCell>Condominium</TableCell>
                  <TableCell>Visitors Number</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Arrival Date</TableCell>
                  <TableCell>Entry Date</TableCell>
                  <TableCell>Departure Date</TableCell>
                  {userDatas?.users[0]?.roles === 'admin' || userDatas?.users[0]?.roles === 'user' ? (
                      <TableCell>Actions</TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {console.log(search)}
                {visits
                .filter(filter)
                .slice(0, rowsPerPage).map(visit => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={visit.id}
                    selected={selectedUsers.indexOf(visit.id) !== -1}
                  >
                    <TableCell>
                      {visit.name}
                    </TableCell>
                    <TableCell>
                      {visit.apartment.number}-{visit.apartment.building_id == 1 ?  "A" :
                                                 visit.apartment.building_id == 2 ? "B":
                                                 visit.apartment.building_id == 3 ? "C": "D" }

                                                
                    </TableCell>
                    <TableCell>
                      {visit.visitors_numbers}
                    </TableCell>
                    <TableCell>{'Scheduled'}</TableCell>
                    <TableCell>
                      {moment(visit.arrives_at).format('LLLL')}
                    </TableCell>
                    <TableCell>
                      {(userDatas?.users[0]?.roles === 'portico')
                        ? (visit.checking_in === null 
                            ? <button onClick={() => updateEntryVisit(visit.id)}>Register entry date</button>
                            : moment(visit.checking_in).format('LLLL'))
                        : (visit.checking_in === null ? '-' : moment(visit.checking_in).format('LLLL'))}
                    </TableCell>
                    <TableCell>
                      {(userDatas?.users[0]?.roles === 'portico')
                        ? (visit.checking_out === null 
                            ? <button onClick={() => updateDepartureVisit(visit.id)} >Register departure date</button>
                            : moment(visit.checking_out).format('LLLL'))
                        : (visit.checking_out === null 
                            ? '-' 
                            : moment(visit.checking_out).format('LLLL'))}
                    </TableCell>
                    {userDatas?.users[0]?.roles === 'admin' || userDatas?.users[0]?.roles === 'user' ? (
                      <TableCell>
                        <IconButton aria-label="delete">
                          <DeleteIcon onClick={() => deleteVisit(visit.id)} />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={visits.filter(filter).slice(0, rowsPerPage).length}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  visits: PropTypes.array.isRequired,
  search: PropTypes.string
};

export default UsersTable;
