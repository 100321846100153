import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { SearchInput } from 'components';
import ProviderTable from '../ProviderTable/ProviderTable';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
      height: '42px',
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1)
    },
    spacer: {
      flexGrow: 1
    },
    importButton: {
      marginRight: theme.spacing(1)
    },
    exportButton: {
      marginRight: theme.spacing(1)
    },
    searchInput: {
      marginRight: theme.spacing(1)
    },
    modal: {
      //display: 'flex',
      padding: theme.spacing(2)
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #333',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  
  const ProviderToldbar = props => {
      const { className, query, setQuery, ...rest} = props;
      let history = useHistory();
      const [open, setOpen] = React.useState(false);

      const handleOpen = () => {
          history.push('/providers/Rate');
      };

      const classes = useStyles();

      return(
            <div
            // {...rest}
            // className={clsx(classes.root,className)}>
            //     <div className={classes.spacer}>
            //     <Button
            //     class="btn btn-warning"
            //     variant= "contained"
            //     // onClick={handleOpen}
            //     >
            //     New Provider                    
            //     </Button>
            >
                <div className={classes.row}>
                    <SearchInput
                    className={classes.searchInput}
                    placeholder="search provider"
                    // onChange={setQuery}
                    // value={query}
                    />
                </div>
                </div>
            // </div>

      );
  };

  ProviderTable.PropTypes = {
      className: PropTypes.string,
      query: PropTypes.string,
      setQuery:PropTypes.func
  };

  export default ProviderTable;