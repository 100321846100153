import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import {  ConciergeItem } from './components';
import UserDataStore from '../../UserDataStore';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";

const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
    id
  }
}`;

const GETAREAS = gql`
subscription common_areas {
common_areas(order_by: {id: asc}) {
  common_area_type_id
  created_at
  id
  image
  name
  updated_at
  common_area_type {
    created_at
    id
    name
    updated_at
  }
}
}
`;

// subscription common_areas{
//   common_areas {
//     common_area_type_id
//     created_at
//     id
//     image
//     name
//     updated_at
//     common_area_type {
//       created_at
//       id
//       name
//       updated_at
//     }
//   }
// }

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    backgroundImage: 'url(/images/alberca.jpg)',
    backgroundSize: 'cover',
    Height: '3000px',
    width: 'auto%',
  },
  customBackground: {
     //  background: '#000428',
    //  background: '-webkit-linear-gradient(to right, #004e92, #000428)', 
    //  background: 'linear-gradient(to right, #004e92, #000428)' ,
    backgroundImage: 'url(/images/alberca.jpg)',
    // backgroundImage: 'no-repeat',
    // backgroundImage: 'fixed',
    // backgroundImage: 'center',
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
    fontFamily: 'sans-serif',
    Height: '3000px',
    width: 'auto%',
    margin: 'auto 10px',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  
}));

const Concierge = () => {
  const [openModal,setOpenModal] = useState(false);
  const { loading,error,data } = useSubscription(GETAREAS);
  UserDataStore.setTitle('concierge');
  const classes = useStyles();
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});

  const handleModal = () => {
    setOpenModal(!openModal);
  };
  if(loading) return(
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        <h1>loading....</h1>
      </Grid>
    </div>
  )
  return (
    
    <div className={classes.root}>
      <Grid
        container
        spacing={4}
      >
        {data.common_areas.map(({id,name,image})=>{
          return (
            <Grid item lg={4} md={6} xs={12} >
              <ConciergeItem label={name} img={image} idArea={id} route={`/concierge/calendar/${id}`} />
            </Grid>)
        })}
      </Grid>
    </div>
  );
};

export default Concierge;
