import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
const useStyles = makeStyles(() => ({
  root: {}
}));

const Gform = props => {
  const { className, handleClose, ...rest } = props;

  const classes = useStyles();

  // const [formState, setFormState] = useState({
  //   isValid: false,
  //   values: {},
  //   touched: {},
  //   errors: {}
  // });

  // useEffect(() => {
  //   const errors = validate(formState.values, schema);

  //   setFormState(formState => ({
  //     ...formState,
  //     isValid: errors ? false : true,
  //     errors: errors || {}
  //   }));
  // }, [formState.values]);

  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
      >
        <CardHeader
          subheader="Capture user information"
          title="New User"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Last name"
                label="Last name"
                margin="dense"
                name="last_name"
                // onChange={handleChange}
                // required
                // value={values.last_name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            //onClick={saveInfo}
          >
            Save
          </Button>
          <Button
            variant="contained"
            onClick={handleClose}
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

Gform.propTypes = {
  className: PropTypes.string
};

export default Gform;
