import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useHistory } from "react-router-dom";

const UPDATEUSERGQL = gql`
mutation update_own_user($id: Int!,$email: String!, $first_name: String!,$last_name: String!,$phone_number: String!){
  update_users_by_pk(pk_columns: {id: $id}, _set: {email: $email, first_name: $first_name, last_name: $last_name, phone_number: $phone_number}) {
    email
    first_name
    last_name
    id
    phone_number
  }
}
`;
function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles(theme => ({
  root: {},
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));


const AccountDetails = props => {
  const { className, user, ...rest } = props;
  const classes = useStyles();
  var auth = firebase.auth();
  let history = useHistory();

  const [values, setValues] = useState((user != null)?
  {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone_number: user.phone_number
  }:
  {
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: ''
  });
  const [snackStatus, setsnackStatus] = useState({open:false,message:'',type:''});

  const handleCloseSnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setsnackStatus({open:false});
  };
  const [UPDATEUSER,{ data, error, loading }] = useMutation(UPDATEUSERGQL)

  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const onSubmit = event => {
    UPDATEUSER({variables:values})
    .then((response)=>{
      setsnackStatus({open:true,message:'Your info was updated',type:'success'})
    })
    .catch((errorCatch)=>{
      setsnackStatus({open:true,message:'Something was wrong',type:'error'})
    })
    event.preventDefault();
  };

  const saveInfo  = () => {
    console.log("guardar info")
  }
  const ShowMeUser = () => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        // User is signed in.
        console.log("positivo " + user.email);
        sentEmail(user.email);
      
      } else {
        // No user is signed in.
      }
    });
  }
  const sentEmail = (user) => {
    console.log("el user es: " + user);
    auth.sendPasswordResetEmail(user);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        onSubmit = {onSubmit}
        autoComplete="off"
        //noValidate
      >
        <input type="hidden" name="id" value={values.id} />
        <CardHeader
          subheader="The information can be edited"
          title="Profile"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                margin="dense"
                name="first_name"
                onChange={handleChange}
                required
                value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Last name"
                margin="dense"
                name="last_name"
                onChange={handleChange}
                required
                value={values.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Email Address"
                margin="dense"
                name="email"
                disabled
                onChange={handleChange}
                required
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Phone Number"
                margin="dense"
                name="phone_number"
                onChange={handleChange}
                type="number"
                value={values.phone_number}
                variant="outlined"
              />
            </Grid>
            
          </Grid>
        </CardContent>
        <Divider />

        <CardActions>
          <div >
          <Button
            color="primary"
            disabled={loading}
            size="large"
            type="submit"
            variant="contained"
            onClick= {saveInfo}
          >
            Save details
          </Button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <Button 
          color="primary"
          // disabled={loading}
          size="large"
          type="submit"
          variant="contained" 
          onClick={()=>{
            history.push("/settings")
          }}
          >
            Change Password
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </CardActions>

      </form>
      <Snackbar open={snackStatus.open} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity={snackStatus.type}>
          {snackStatus.message}
        </Alert>
      </Snackbar>
 
    </Card>
  );
};

AccountDetails.propTypes = {
  className: PropTypes.string
};

export default AccountDetails;
