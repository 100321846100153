import React, { useState,Fragment } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button, Form, Input } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import PersonIcon from '@material-ui/icons/Person';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useQuery,useMutation,useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import { useLocation,useParams } from 'react-router';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import TablePagination from '@material-ui/core/TablePagination';

const GETDATAPROVIDER = gql`
subscription MySubscription($id_provider: Int!) {
    providers_ratings(where: {id_provider: {_eq: $id_provider}}) {
      id
      id_provider
      id_user
      comment
      rating
    }
  }
  
`;

const useStyles = makeStyles(theme => ({
    root: {
      height: '100%'
    },
    head: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
      },
      container: {
        maxHeight: 440,
      },
    table: {
        height: 600,
        
        maxWidth: 650,
      },
}))

const columns = [
    { id: 'name', label: 'Comment', minWidth: 170 },
     { id: 'code', label: 'Rating', minWidth: 100 },
];

  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  } 
  
  const rows = [
        createData('India', 'IN', 1324171354, 3287263),
        createData('China', 'CN', 1403500365, 9596961),
        createData('Italy', 'IT', 60483973, 301340),
        createData('United States', 'US', 327167434, 9833520),
        createData('Canada', 'CA', 37602103, 9984670),
        createData('Australia', 'AU', 25475400, 7692024),
        createData('Germany', 'DE', 83019200, 357578),
        createData('Ireland', 'IE', 4857000, 70273),
        createData('Mexico', 'MX', 126577691, 1972550),
        createData('Japan', 'JP', 126317000, 377973),
        createData('France', 'FR', 67022000, 640679),
        createData('United Kingdom', 'GB', 67545757, 242495),
        createData('Russia', 'RU', 146793744, 17098246),
        createData('Nigeria', 'NG', 200962417, 923768),
        createData('Brazil', 'BR', 210147125, 8515767),
      ];



const RateTable = (props) => {
    const {id} = props;
    const classes = useStyles();
    const {data:dataRatings,loading:loadingRatings,error:errorRatings} = useSubscription(GETDATAPROVIDER,{variables:{id_provider:id}});
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
   
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };
    
if ( loadingRatings )
    return <p>loading</p>


return (
//     <div style={{ height: 400, width: '50%' }}>
//      <TableContainer component={Paper}>
//     <Table className={classes.table} aria-label="customized table">

//       <TableHead>
//         <TableRow>
//            <TableCell>Comment </TableCell> 
//           {/* <TableCell align="right">Calories</TableCell> */}
//           <TableCell >Rating</TableCell>
//         </TableRow>
//       </TableHead>
//       <TableBody>
//          {dataRatings.providers_ratings.map((current) => (
//           <TableRow key={current.name}>
//             <TableCell component="th" scope="row">{current.comment}</TableCell>
//              <TableCell >{current.rating}</TableCell>
//           </TableRow>
//         ))}

// </TableBody>
// </Table>
// </TableContainer> 
// </div>      

<Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {dataRatings.providers_ratings.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (                  
                <TableRow 
                hover role="checkbox" 
                tabIndex={-1} 
                key={row.code}>

                    <TableCell>{row.comment}</TableCell>
                    <TableCell> <Rating name="read-only" value={row.rating} readOnly/> {row.rating}</TableCell>
                  {/* {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })} */}
                </TableRow>
              );
            })}

          </TableBody>


        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>

);
};
export default RateTable;