import React from 'react';
import { Switch, Redirect,withRouter } from 'react-router-dom';
import userDataStore from '../../UserDataStore';
import { gql } from 'apollo-boost';
import { useQuery, useMutation } from '@apollo/react-hooks';

const GETUSERBYEMAIL = gql`
query get_userbyemail($email: String = "") {
  users(where: {email: {_eq: $email}}) {
    email
    id
    first_name
    last_name
    uid
    phone_number
  }
}
`;

const UPDATEUSER = gql`
mutation update_user_uid($id: Int!, $uid: String!) {
  users:update_users_by_pk(pk_columns: {id: $id}, _set: {uid: $uid}) {
    id
    first_name
    last_name
    email
    phone_number
    uid
  }
}
`;

const Validation = props => {
  const {history,handleAuthState,authState,...rest} = props;
  const {loading,data,error} = useQuery(GETUSERBYEMAIL,{variables:{email:userDataStore.userData.email}});
  const [updateUserUid] = useMutation(UPDATEUSER);
if(loading)
return <h1>...loading</h1>

if(error !== undefined)
return <h1>ERROR</h1>;
    let user = data.users[0];
    let uid = authState.user.uid;
    if(user.uid === '')
    updateUserUid({variables:{id:user.id,uid:uid}}).then((datas)=>{
      console.log(datas);
      let authstate = authState;
      authState.status = 'in';
      handleAuthState(authState);
    })
    //return <Redirect to={'/dashboard'} />
return (<di>
  hola mundo
</di>)
// else{
//   if(data.users.length > 0){
//     let user = data.users[0];
//     let uid = authState.user.uid;
//     if(user.uid === '')
//     return updateUserUid({variables:{id:user.id,uid:uid}}).then(()=>{
//       let authstate = authState;
//       authState.status = 'in';
//       handleAuthState(authState);
//       return <Redirect to={'/dashboard'} />
//     });
//     let authstate = authState;
//     authState.status = 'in';
//     handleAuthState(authState);
//     return <Redirect to={'/dashboard'} />
//   } else {
//     return <h1>SORRY BUT THIS USER IS NOT ALLOWED TO ACCESS, IF YOU ARE COSTA BONITA OWNER ASk TO YOUR MANAGER FOR YOUR ACCESS</h1>
//   }
// }
};

export default withRouter(Validation);