import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';
import { Button, Modal, Backdrop, Fade } from '@material-ui/core';
import { SearchInput } from 'components';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  modal: {
    //display: 'flex',
    padding: theme.spacing(2)
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #333',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const UsersToolbar = props => {
  const { className, query, setQuery, ...rest } = props;
  let history = useHistory();
  const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    history.push('/arrivals/create');
  };

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
        >
          New Visit
        </Button>
      </div>
      <div className={classes.row}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search"
          onChange={setQuery}
          value={query}
        />
      </div>

    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string,
  setQuery: PropTypes.func
};

export default UsersToolbar;
