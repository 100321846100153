import React from 'react';
import {Typography} from '@material-ui/core';
import { Observer } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/styles';
import UserDataStore from './UserDataStore';

const useStyles = makeStyles(theme => ({ 
    title: {
        textAlign:'center',
        textTransform:'uppercase',
        color: '#FFFFFF',
      }
}));
const TitleObserver = () => {
    const classes = useStyles();
  return (
    <div>
      <Observer>{() => <Typography className={classes.title} variant="h4">
          {UserDataStore.currentTitle}
        </Typography>}</Observer>
    </div>
  );
}

export default TitleObserver;