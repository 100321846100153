import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card } from '@material-ui/core';
import ProviderItem from '../ProviderItem/ProviderItem';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    }
  }));


const ProviderTable = ({props,data})  => {
    const { loading:loadingProviders, error:errorProviders, data:dataProviders } = data ;
    const classes = useStyles();
    let history = useHistory();
    console.table(dataProviders)


    const handleRate = event => {
        let id = event.currentTarget.getAttribute('data-id');
        history.push(`/provider/rate/${id}`);
      }
    

    if(loadingProviders)
    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12} >Loading......</Grid>
            </Grid>
        </div>
    );

    return(
        <Grid container spacing={4} >
                {data.services.map((currentService) => {
                    return currentService.providers_services.map((currentProvider) => {
                         return (
                             <Grid item xs={12} >
                             <Card>
                              <ProviderItem
                                 service = {currentService.name}
                                 phone={currentProvider.provider.phone}
                                 email={currentProvider.provider.email}
                                 fullName={currentProvider.provider.name}
                                 rating={currentProvider.provider.rate}
                                 />    
                                 <button 
                                 type="button" 
                                 class="btn btn-warning"
                                 data-id={currentProvider.id}
                                 data-name={currentProvider.provider.name}
                                 onClick={handleRate}
                                //  onClick={testRate}
                                 >Rate</button>
                               </Card>
                            </Grid> 
                         )
                     })
                })}
            </Grid>

    );
};

export default ProviderTable;