import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import moment from 'moment';
import avatar from './User_Avatar_Default.png';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  contenNumberCondo: {
    alignContent: 'right'
  },
  title: {
    fontWeight: 700,
    textTransform:'uppercase',
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  mainContainer: {
    height:250,
    with:300,
    backgroundSize: 'cover'
  },
  imgDimensions: {
    height: '50px',
    width: '50px'
  },
}));

const DirectoryItem = props => {
  const { className, building, email, phone, fullName, condoNumber, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
          <Grid
            container
            direction="row"
            spacing={1}
          >
            <Grid item>
              <Typography
                className={classes.title}
                color="primary"
                // style={{textAlign:'center',fontSize:"2.5em",fontWeight:"900",lineHeight:"initial"}}
                variant="h5"
              >
                <img src={avatar} className={classes.imgDimensions}></img>
                {condoNumber}
              </Typography>
              <Typography
              // style={{textAlign:'center',fontSize:"2.5em",fontWeight:"900",lineHeight:"initial"}}
              variant="h5"
              >
                {building}
              </Typography>
            </Grid>
            <Grid item style={{flex:1}}>
                <Grid container direction="column" justify="center" style={{height:"100%"}}>
                  <Grid item>
                    <Grid container><PersonIcon/><Typography style={{marginLeft:"8px"}}>{fullName}</Typography></Grid>
                    <Grid container><MailIcon/><a href={`mailto:${email}`}><Typography style={{marginLeft:"8px"}}>{email}</Typography></a></Grid>
                    <Grid container><PhoneIcon/><a href={`tel:${phone}`}><Typography style={{marginLeft:"8px"}}>{phone}</Typography></a></Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

DirectoryItem.propTypes = {
  className: PropTypes.string,
  condoNumber: PropTypes.string,
  building: PropTypes.string,
  email: PropTypes.string,
  fullName: PropTypes.string,
  phone: PropTypes.string
};

export default DirectoryItem;
