import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import moment from 'moment';
import { useQuery,useMutation } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';


const GETAPARTMENT = gql`
query getapartments($user_id: Int) {
  apartment(where: {user_id: {_is_null: true}}, order_by: {id: asc}) {
    id
    number
    updated_at
    created_at
    building_id
    building {
      created_at
      id
      updated_at
      name
    }
    user_id
    user {
      first_name
      last_name
      id
    }
  }
}
`;
const FIREBASEUSER = gql`
query registeUser($email: String!,$displayName: String!) {
  registerUser(email: $email, displayName: $displayName) {
    displayName
    email
    uid
  }
}
`;

const INSERTUSER = gql`
mutation insertuser($email: String!, $first_name: String!, $last_name: String!, $uid: String!, $apartment_id: Int!) {
  user: insert_users_one(object: {email: $email, first_name: $first_name, last_name: $last_name, uid: $uid, apartments: {data: {id: $apartment_id, number: 0}, on_conflict: {constraint: apartment_pkey, update_columns: user_id}}}) {
    first_name
    id
    last_name
    email
  }
}
`;
const useStyles = makeStyles(() => ({
  root: {}
}));

const VisitForm = props => {
  const { className, handleClose, ...rest } = props;
  // const [addUser, { data:userData,loading:loadingUser }] = useMutation(INSERTUSER);
  // const { loading:loadingApartment, error:errorApartment, data:dataApartment } = useQuery(GETAPARTMENT);
  // const {refetch:createfirebase} = useQuery(FIREBASEUSER);
  const classes = useStyles();
  // const [values, setValues] = useState({
  //   first_name: '',
  //   last_name: '',
  //   email: '',
  //   uid:'',
  //   apartment_id:0
  // });

  // const handleChange = event => {
  //   setValues({
  //     ...values,
  //     [event.target.name]: event.target.value
  //   });
  // };

  // const handleSubmit = event => {
  //   createfirebase({email:values.email,displayName:values.first_name})
  //   .then(({data,loading})=>{
  //     let userRecord = data.registerUser;
  //     let _values = values;
  //     _values.uid = userRecord.uid;
  //     return addUser({variables:values}).then(()=>{
  //       handleClose()
  //     })
  //   });
  //   event.preventDefault();
  // };

  // if(loadingApartment)
  // return (
  //   <Card
  //     {...rest}
  //     className={clsx(classes.root, className)}
  //   >loading......</Card>
  // )

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form
        // onSubmit={handleSubmit}
        autoComplete="off"
      >
        <CardHeader
          subheader="Capture user information"
          title="New User"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="Condominium"
                margin="dense"
                name="apartment_id"
                // onChange={handleChange}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                // value={values.apartment_id}
                variant="outlined"
              >
                {/* <option value={0}>selecionar</option> */}
                {/* {dataApartment.apartment.map(apartment => {
                  return (
                    <option
                    key={apartment.id}
                    value={apartment.id}
                    >
                      {`${apartment.number}-${apartment.building.name}`}
                    </option>
                  );
                })} */}
              </TextField>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Email"
                label="Email"
                margin="dense"
                name="email"
                // onChange={handleChange}
                required
                // value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="First name"
                label="First name"
                margin="dense"
                name="first_name"
                // onChange={handleChange}
                required
                // value={values.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                helperText="Last name"
                label="Last name"
                margin="dense"
                name="last_name"
                // onChange={handleChange}
                required
                // value={values.last_name}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            //onClick={saveInfo}
          >
            Save
          </Button>
          <Button
            variant="contained"
            // onClick={handleClose}
          >
            Cancel
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

VisitForm.propTypes = {
  className: PropTypes.string
};

export default VisitForm;
