import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { UsersToolbar, UsersTable } from './components';
import UserDataStore from '../../UserDataStore';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GETUSERS = gql`
subscription getusers{
  users {
    created_at
    email
    first_name
    id
    img_profile
    last_name
    phone_number
    public_contact_info
    uid
    updated_at
    user_name
  }
}
`;


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = () => {
  UserDataStore.setTitle('');
  UserDataStore.setTitle('Users');
  const classes = useStyles();
  const [query,setQuery] = useState('');
  const {data,loading,error} = useSubscription(GETUSERS);
  const HanddleQueryChange = (event) => {
    setQuery(event.target.value);
  };

  
  if(loading)
  return (
    <div className={classes.root}>
      <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)} />
      <div className={classes.content}>
        <h1>loading.......</h1>
      </div>
    </div>
  );

  
  try {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
         
        <UsersTable users={data.users} search={query}/>
        </div>
      </div>
    );


  } catch (errorCatch) {
    return (
      <div className={classes.root}>
        <UsersToolbar query={query} setQuery={HanddleQueryChange.bind(this)}/>
        <div className={classes.content}>
          <h1>{error.message}</h1>
        </div>
      </div>
    );
  }
};

export default UserList;
