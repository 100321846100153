import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, StylesContext } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography, ButtonBase } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    fontSize:32,
    textTransform:'uppercase',
    color:'#FFFFFF'
  },
  avatar: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  mainContainer: {
    height:250,
    with:300,
    backgroundSize: 'cover'
  }
}));

const ConciergeItem = props => {
  const { className, label, route, img, file, onClick, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.mainContainer}
            style={{
              background:`url(https://ownersapp.costabonita.mx/assets/images/${img})`,
              backgroundColor:'gray',
              backgroundSize:'cover',
              backgroundRepeat:'no-repeat',
              boxShadow:'inset 0 0 0 2000px rgba(51,51,51,0.3)'
            }}

          >
            <Grid item>
              <ButtonBase onClick={()=>{
                onClick(file);
              }}>
              <Typography
                className={classes.title}
                gutterBottom
                variant="title"
              >
                {label}
              </Typography>
              </ButtonBase>
            </Grid>
          </Grid>
      </CardContent>
    </Card>
  );
};

ConciergeItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  route: PropTypes.string,
  img: PropTypes.string,
  file: PropTypes.string,
  onClick: PropTypes.func
};

export default ConciergeItem;
