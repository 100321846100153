import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Button, Toolbar } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    //boxShadow: 'none'
    boxShadow:'1px 1px 50px #000',
    backgroundColor: 'rgba(212, 152, 152, 0)',
    ackgroundcolor:'blue',
    position: 'absolute',
    display: '20%',
    margin: 'auto',
    borderRadius: 10,
    background: 'rgba(112, 87, 87, 0.25);',
  },

  topBar: {
    
  },


}));

const Topbar = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  function hideBar () {
      console.log("prueba")
  }

  

  return (
  
    <AppBar
       {...rest}
       className={clsx(classes.root, className)}
       color="primary"
       position="fixed"
     >
       <Toolbar>
         <RouterLink to="/">
           <img
             alt="Logo"
             src="/images/logos/logo.png"
           />
           
         </RouterLink>
       </Toolbar>
     </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
