import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { map } from 'underscore';
import { Button, ClickAwayListener, Grid } from '@material-ui/core';
import { CustomAccordionDetail } from './components';
import { ContactItem } from 'views/Arrivals/components/UsersToolbar/Components/VisitForm/components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    rootDowpdown: {
        position: 'relative',
        marginTop: 20
    },
    dropdown: {
        zIndex: 1,
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
);

const CustomAccordion = props => {
    const { AccordionItems, Categories, Value:{phones,emails,addresses}, ContactType, handleChangeContactElements, handleChangeContactAccordion } = props;
    const classes = useStyles();
    const [openCategories, setOpenCategories] = useState(false);
    const [accordionItems,setAccordionItems] = useState(AccordionItems);

    const removeItem = (event) => {
      let id = event.currentTarget.getAttribute('data-id');
      let pea = event.currentTarget.getAttribute('data-pea');
      let newRow = {};
      newRow.id = id;
      handleChangeContactElements(ContactType,pea,newRow,1);
      handleClickAwayFromAddBtn();
    };
    const handleClickAddBtn = () => {
        setOpenCategories((prev) => !prev);
      };
    
      const handleClickAwayFromAddBtn = () => {
        setOpenCategories(false);
      };

      const handleClickCategory = (event) => {
        let value = event.currentTarget.getAttribute('data-value');
        let newRow = {}
        let pea = '';
        let tempid = '';
        switch(value){
          case 'Phones':
            newRow.phone = {
              number: '',
              id:0
            };
            newRow.id = 0;
            pea='phones';
            tempid = `p${Date.now()}`;
            break;
          case 'Emails':
            newRow.email = {
              direction: '',
              id:0
            };
            newRow.id = 0;
            pea='emails';
            tempid = `e${Date.now()}`;
            break;
          case 'Addresses':
            newRow.address = {
              street : '',
              neighborhood : '',
              interior : '',
              exterior : '',
              city : '',
              zip_code : '',
              id : 0
            };
            newRow.id = 0;
            pea='addresses';
            tempid = `a${Date.now()}`;
            break;
        }
        newRow.tempid = tempid;
        handleChangeContactElements(ContactType,pea,newRow);
        handleClickAwayFromAddBtn();
      };

      return (
        <div className={classes.root}>
          {(phones === undefined)?null:phones.map((item)=>{
            return (
            <ExpansionPanel>
                <ExpansionPanelSummary 
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Button data-id={(item.tempid === undefined)?`${item.tempid}`:`p${item.id}`} data-pea="phones" onClick={removeItem}><CloseIcon /></Button><Typography className={classes.heading}>Phone</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ContactItem ContactType={ContactType} Value={item} ItemType={"Phones"} handleChangeContactAccordion={handleChangeContactAccordion.bind(this)} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            );
          })}
          {(emails === undefined)?null:emails.map((item)=>{
            return (
            <ExpansionPanel>
                <ExpansionPanelSummary 
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Button data-id={(item.tempid === undefined)?`${item.tempid}`:`e${item.id}`} data-pea="emails" onClick={removeItem}><CloseIcon /></Button><Typography className={classes.heading}>Emails</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ContactItem ContactType={ContactType} Value={item} ItemType={"Emails"} handleChangeContactAccordion={handleChangeContactAccordion.bind(this)} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            );
          })}
          {(addresses === undefined)?null:addresses.map((item)=>{
            return (
            <ExpansionPanel>
                <ExpansionPanelSummary 
                    expandIcon={<ExpandMoreIcon />}
                >
                    <Button data-id={(item.tempid === undefined)?`${item.tempid}`:`a${item.id}`} data-pea="addresses" onClick={removeItem}><CloseIcon /></Button><Typography className={classes.heading}>Addresses</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <ContactItem ContactType={ContactType} Value={item} ItemType={"Addresses"} handleChangeContactAccordion={handleChangeContactAccordion.bind(this)} />
                </ExpansionPanelDetails>
            </ExpansionPanel>
            );
          })}
          <ClickAwayListener onClickAway={handleClickAwayFromAddBtn}>
          <div className={classes.rootDowpdown}>
          <Button onClick={handleClickAddBtn}>
              <Typography>ADD</Typography>
          </Button>
          {openCategories ? (
            <div className={classes.dropdown}>
                <Grid container direction="column">
                {Categories.map((category)=>{
                      return (<Button data-value={category} onClick={handleClickCategory}>{category}</Button>);
                  })}
                </Grid>
            </div>
          ) : null}
        </div> 
          </ClickAwayListener>
        </div>
      );
};

CustomAccordion.propTypes = {
    AccordionItems : PropTypes.array,
    Categories : PropTypes.array
};

export default CustomAccordion;