import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card } from '@material-ui/core';
import userDataStore from '../../../../UserDataStore';
import { useQuery,useMutation,useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import RateItem from './RateItem';
import clsx from 'clsx';
import RateTable from './RateTable';
import firebase, { auth } from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { useLocation,useParams } from 'react-router';



const PROVIDERSRATTINGS = gql`
query MyQuery($provider_id: Int_comparison_exp!) {
    history_comments(where: {provider_id: $provider_id}) {
      comment
      rating
    }
  }  
  `;
  const ALLRATINGS = gql`
  query MyQuery {
    history_comments {
      comment
      rating
    }
  }
  `; 
  const   CREATERATING = gql`
mutation MyMutation($object: providers_ratings_insert_input!) {
  insert_providers_ratings_one(object: $object) {
    id
    comment
    id_provider
    id_user
    rating
  }
}
`;
const GETDATAPROVIDER = gql`
query MyQuery($id_provider: Int ! ) {
  providers_ratings(where: {id_provider:{_eq: $id_provider }}) {
    id
    id_provider
    comment
    id_user
    rating
  }
}
`;
const GETUSERROLEBYEMAIL = gql `
subscription getRole($email: String!) {
  users:users(where: {email: {_eq: $email}}) {
    first_name
    last_name
    email
    roles
    id
  }
}`
; 


const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start' // if you want to fill rows left to right
    },
    whitebackgound:{
      backgroundColor: 'white'
    },
    item: {
      width: '50%' // is 50% of container width
    }

  }));

const Rate = () =>{ 
    //const providers = useQuery(PROVIDERSRATTINGS);
    const { id } = useParams();
     var provider_id = 2;
     const emailCustom =  firebase.auth().currentUser && firebase.auth().currentUser.email;
    const {data:dataRatings,loading:loadingRatings,error:errorRatings} = useQuery(GETDATAPROVIDER,{variables:{id_provider:id}});
    const {data:userData,loading:userLoadingError,error:userDataError} = useSubscription(GETUSERROLEBYEMAIL,{variables:{email:emailCustom}});
    const {loading,data,error} = useQuery(ALLRATINGS);
    userDataStore.setTitle('Rate Providers');


    const classes = useStyles();
     if( loading  || loadingRatings || userLoadingError ) return (
         <div className={classes.root}>
             <Grid 
             container 
             spacing={4} >
                 <Grid item xs={12} >Loading......</Grid>
             </Grid>
         </div>);
    return(

<Grid
  container
  direction="row"
  justify="center"
  alignItems="center"
  >
      <div>
          {data.history_comments.map((currentService) => {
                  return ( 
                     <Grid className={classes.root}>
                  <RateItem
                  userId={userData.users[0].id}
                 userRating={currentService.rating}
                  userComment={currentService.comment}
                  providerName={currentService.name} 
                  id={id} /> 
                   </Grid>                                    
                         );
          })}  
       </div>

       <div className={classes.item}>
          {/* {dataRatings.providers_ratings.map((current)=> {
            return( */}
           <RateTable
         
          id={id} />
          {/* );})} */}
      </div>
  </Grid>


    );
};

export default Rate;