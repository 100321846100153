import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Card } from '@material-ui/core';
import userDataStore from '../../UserDataStore';
import DirectoryItem from './components/DirectoryItem';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
const GETBUILDINGS = gql`
query getBuildings {
    buildings {
        created_at
        id
        name
        updated_at
        apartments(where: {user: {public_contact_info: {_eq: true}}}) {
            building_id
            created_at
            id
            number
            updated_at
            user_id
            user {
            created_at
            email
            first_name
            id
            img_profile
            last_name
            phone_number
            public_contact_info
            uid
            updated_at
            user_name
            }
        }
        
    }
  }
`;
const useStyles = makeStyles(theme => ({
    root: {
      padding: theme.spacing(4)
    }
  }));
const Directory = () => {
    const { loading, error, data } = useQuery(GETBUILDINGS);
    userDataStore.setTitle('');
    userDataStore.setTitle('directory');
    
    const classes = useStyles();
    if(loading)
    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item xs={12} >Loading......</Grid>
            </Grid>
        </div>
    );
    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                {data.buildings.map((currentBuilding) => {
                    return currentBuilding.apartments.map((currentApartment)=>{                        
                        return (
                            <Grid item xs={12} >
                                <DirectoryItem
                                condoNumber={currentApartment.number}
                                building = {currentBuilding.name}
                                phone={currentApartment.user.phone_number}
                                email={currentApartment.user.email}
                                fullName={`${currentApartment.user.first_name} ${currentApartment.user.last_name}`}
                                />
                            </Grid>
                        );
                });
                })}
                
            </Grid>
        </div>
    );
};

export default Directory;