import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import userDataStore from '../../../../../../UserDataStore';
import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';

const GETLOGEDUSER = gql`
subscription getLogedUser($uid: String) {
  users(where: {uid: {_eq: $uid}}) {
    created_at
    email
    first_name
    id
    img_profile
    phone_number
    last_name
    public_contact_info
    uid
    updated_at
    user_name
  }
}
`;


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = props => {
  const { className, authState, ...rest } = props;
  const {data:user,loading,error} = useSubscription(GETLOGEDUSER,{variables:{uid:userDataStore.userData.uid}});
  const classes = useStyles();

  //const {userData:user} = userDataStore;
  if(loading){
  return (<h1>loading...</h1>)
  } else {
    if(error !== undefined){
      return <div
                {...rest}
                className={clsx(classes.root, className)}
              ></div>
    } else {
      return user.users.map(current=>
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          {/* <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={user.avatar}
            to="/settings"
          /> */}
          <Typography
            className={classes.name}
            variant="h4"
          >
            {`${current.first_name} ${current.last_name}`}
          </Typography>
        <Typography variant="body2">{current.email}</Typography>
        </div>
      );
    }
  }
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
