import emailjs from 'emailjs-com';

/**
 *
 * @param {string[]} emails
 * @param {string} subject
 * @param {string} link
 * @param {string} notification
 */
const sendEmailHelper = (emails, subject, link, notification) => {
  const CHUNK_SIZE = 20;

  for (let i = 0; i < emails.length; i += CHUNK_SIZE) {
    const subarray = emails.slice(i, CHUNK_SIZE + i);
    const filteredEmails = subarray.filter(
      email => email !== null || email !== ''
    );
    const emailData = {
      userEmail: filteredEmails,
      notification_type: notification,
      file_link: `https://ownersapp.costabonita.mx/assets/${link}`
    };
    // 'user_KvIWsYcawt30pHo6Ra2qK'
    emailjs
      .send('CostaBonita', subject, emailData,'wFc4AbNupzZxb4txi')
      .catch(err => {
        console.log(err);
      });
  }
};

export default sendEmailHelper;
